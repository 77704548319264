
.subnovelContent{
    margin-top: 3%;
    margin-right: 1%;
    margin-left: 5%;
}

.novelCover{
    width: 400px;
}

.mainTable{
    width: 100% !important;
}

.statCard{
    width: 400px;
    background-color: #5EC2BB !important;
    color: #E8F3F1 !important;
    border-color: #C3F2EF !important;
}
.cardList{
    background-color: #5EC2BB !important;
    color: #E8F3F1 !important;
    border-color: #C3F2EF !important;
}
.novelBody {
    margin-left: 2%;
    margin-right: 10%;
    margin-top: 0%;
    
    font-size: 18px;
    
}
.novelButton{
    background-color: #5EC2BB !important;
    border-color: #5EC2BB !important;
    color: #E8F3F1 !important;
    width: 50% !important;
}