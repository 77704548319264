*{
    margin: 0;
    padding: 0;
}

body {
    background-color: #E8F3F1;
}

.sampleBlock{
    width: 80%;
    margin-left: 10%;
    margin-bottom: 3%;
    color: #E8F3F1;
    background-color: #5EC2BB;
}
.textBlock{
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    margin-bottom: 2%;
    
}