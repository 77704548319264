.bar{
    background-color: #5EC2BB;
    color: #E8F3F1;
    left:0;
    bottom:0;
    right:0;
    position: fixed;
    
}

.barText{
    margin-top: 5px;
    margin-left: 20px;

    font-size: 13px;
}