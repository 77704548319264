*{
    margin: 0;
    padding: 0;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

body {
    background-color: #C3F2EF !important;
}

.main_content{
    color: black;
}

.page_content {
    margin-left: 10%;
    margin-top: 2%;
    margin-right: 10%;
    text-align: left;
    font-size: 20px;
}
.page_content_2 {
    margin-left: 10%;
    text-align: left;
    font-size: 20px;
    margin-right: 10%;
    margin-top: 1%;
    margin-bottom: 1%;
}
.table_main {
    width: 75%;
}
.table_info {
    font-size: 16.5px;
    width:550px;
    table-layout: fixed;
}

.game_poster{
    display: block;
    width: 95%;
    margin-left: 0px;
}

.video_embed{
    display:block;
    width: 100%;
    height: 100%;
}
.content_table{
    width: 100%;
    height: 100%;
    table-layout:fixed;
}
.galeryImage{
    display: block;
    height: 97.5%;
    width: 97.5%;
    margin-left: 10px;
    margin-right: 0px;
    margin-top: 5px;
}