.navbar_main {
    background-color: #5EC2BB;
    
}
.navbar_logo{
    color:#E8F3F1 !important;
    margin-left: 3%;
    
}
.navbar_text{
    color:#E8F3F1 !important;
    margin-left: 4%;
}

.logo{
    display: flex;
    width: 28px;
    position: relative;
}
