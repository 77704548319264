
*{
    margin: 0;
    padding: 0;
}

body {
    background-color: #E8F3F1;
}

.main_content{
    
   
    color: black;
}

.games_intro {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 10%;
    margin-right: 20%;
    text-align: left;
    font-size: 30px;
}
.games_intro_header {
    font-size: 60px;
}
.games_text{
    color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    
    margin-top: 15%;
    margin-left: 15%;
    margin-right: 45%;

    font-size: 25px;
    z-index: 5;
}
.games_text_header{
    font-size: 50px;
}
.game_buttons{
    margin-right: 80%;
    background-color: rgba(0,0,0,.35)  !important;
    border-color: white !important;
    z-index: 5;
}

.videos{
    width:100%;
    height:100%;
    object-fit: cover;
}
.video_overlay{
    position: absolute;
   
    width: 100%;
    height: 100%;
    
    background-color: rgba(0,0,0,.75)
}

