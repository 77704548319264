*{
    margin: 0;
    padding: 0;
    font-family: futura !important;
}

body {
    background-color: #C3F2EF !important;
}

.main_content{
    color: black;
}

.page_content {
    margin-left: 10%;
    margin-top: 2%;
    margin-right: 10%;
    text-align: left;
    font-size: 20px;
}
.page_content_2 {
    margin-left: 10%;
    text-align: left;
    font-size: 20px;
    margin-right: 10%;
    margin-top: 2%;
    margin-bottom: 1%;
}
.table_main {
    width: 75%;
}
.table_info {
    font-size: 16.5px;
    width:400px;
    table-layout: fixed;
}

.game_poster{
    display: block;
    width: 95%;
    margin-left: 0px;
}

.video_embed{
    display:block;
    width: 100%;
    height: 100%;
}
.content_table{
    width: 100%;
    height: 100%;
    table-layout:fixed;
    
}
.galeryImage{
    display: block;
    height: 215px;
    margin-left: 10px;
    margin-right: 0px;
    margin-top: 5px;
}

.game_Button{
    background-color: #5EC2BB !important;
    border-color: #5EC2BB !important;
    width: 600px !important;
    margin-left: 80px;
    margin-top: 1%;
}
.novel_Button{
    background-color: #5EC2BB !important;
    border-color: #5EC2BB !important;
    width: 600px !important;
    margin-left: 50px;
    margin-top: 1%;
}

.novelPosters{
    width: 325px;
    margin-left: 10%;
    margin-top: 5px;
}
@font-face{
    font-family: futura;
    src: url(../assets/futura.ttf);
}