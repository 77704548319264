
*{
    margin: 0;
    padding: 0;
}

body {
    background-color: #E8F3F1;
}

.content{
    margin-top: -200px;
    margin-right: 1%;
    margin-left: 10%;
}

.placeholder{
    width: 1000px;
}

.caption{
    color: black !important;
    text-align: center;
    width: 50%;
    margin-left:10%;
    font-size: 25px;
    margin-bottom:50%;
}

.carousel{
    color:black;
    scale: 60%;
}

.novel_buttons{
    background-color: rgba(256,256,256,.35)  !important;
    border-color: black !important;
    color: black !important;
    z-index: 5;
    scale: 150%;
}
.test{
    width: 100%;
    font-size: 20px;
}
.sampleCards{
    background-color: #5EC2BB !important;
    color: #E8F3F1 !important;
    border-color: #C3F2EF !important;
    font-size: 12px;
    margin-left: 2%;
    margin-right: 2%;
    height: 260px !important;
    width: 200px !important;
}
.cardButton{
    background-color: #C3F2EF !important;
    color: #5EC2BB !important;
    border-color: #C3F2EF !important;
}